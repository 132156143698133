/**
 * PhoneInput styled container
 *
 * @author: exode <hello@exode.ru>
 */

import styled from 'styled-components';


export const Container = styled.div<{ hasFocus: boolean, height?: string | number }>`
    --shadow-color-focus: var(--accent);
    --shadow-color: var(--separator_common);
    --react-international-phone-flag-width: 26px;
    --react-international-phone-flag-height: 26px;
    --react-international-phone-dropdown-item-height: 34px;
    --react-international-phone-text-color: var(--text_primary);
    --react-international-phone-background-color: var(--field_background);
    --react-international-phone-disabled-text-color: var(--text_secondary);
    --react-international-phone-font-size: var(--vkui--font_text--font_size--compact);
    --react-international-phone-border-radius: var(--vkui--size_border_radius--regular);
    --react-international-phone-country-selector-background-color-hover: var(--field_background);
    --react-international-phone-selected-dropdown-item-background-color: var(--field_background);
    --react-international-phone-border-color: var(--field_border, var(--vkui--color_field_border_alpha));
    --react-international-phone-dropdown-item-font-size: calc(var(--vkui--font_text--font_size--compact) - 1px);
    --react-international-phone-height: ${({ height = 'var(--vkui--size_field_height--compact, 36px)' }) => height};

    &::after {
        box-shadow: ${({ hasFocus }) => hasFocus
                ? 'inset 0 0 0 var(--thin-border) var(--shadow-color-focus) !important'
                : 'inset 0 0 0 var(--thin-border) var(--shadow-color) !important'
        }
    }

    .FormItem--error & {
        --shadow-color: var(--field_error_border);
        --field_background: var(--field_error_background);
    }

    .react-international-phone-input {
        width: 100%;
        border: none !important;
    }

    .react-international-phone-country-selector-dropdown {
        z-index: 1;
        padding: 6px;
        margin-top: 5px;
        --react-international-phone-background-color: var(--background_content);

        border-radius: var(--vkui--size_border_radius_paper--regular);

        border: .5px solid var(--vkui--color_separator_primary, #d7d8d9) !important;
        box-shadow: var(--vkui--elevation1, 0 0 2px rgba(0, 0, 0, .03), 0 2px 2px rgba(0, 0, 0, .06)) !important;

        &__list-item {
            border-radius: 8px;
            --react-international-phone-selected-dropdown-item-background-color: var(--background_hover);

            &--selected {
                color: var(--vkui--color_text_contrast);
                background-color: var(--vkui--color_background_accent);
                --react-international-phone-selected-dropdown-item-dial-code-color: var(--vkui--color_text_contrast);
            }
        }
    }

    .react-international-phone-country-selector-button {
        padding-left: 6px;
        border: none !important;
    }
`;
