/**
 * Parse util
 *
 * @author: exode <hello@exode.ru>
 */

import * as _ from 'lodash';

import { phone } from '@exode-team/phone';

import { regex } from './Regex';


class Parse {

    /**
     * Parse method params
     * @param method
     * @returns {{}}
     */
    static methodParams(method: string) {
        const replaces = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/mg;

        const methodCode = method.toString();

        const start = methodCode.indexOf('(') + 1;
        const finish = methodCode.indexOf(')');

        const brackets = methodCode.slice(start, finish).replace(replaces, '');
        const parsed = brackets.match(/([^\s,]+)/g) || [];

        const parse = (value: string) => {
            const numTypes = [ 'number', 'string' ];
            const isNumeric = numTypes.includes(typeof value) && !isNaN(Number(value));

            return isNumeric
                ? Number(value)
                : (String(value).includes(`'`) ? value.replace(/'/g, '') : value);
        };

        const params: Record<string | number, any> = {};

        for (let i = 0; i < parsed.length; i++) {
            const param = parsed[i];
            const nextIsEqual = parsed[i + 1] === '=';

            params[param] = nextIsEqual ? parse(parsed[i + 2]) : undefined;
            i = nextIsEqual ? i + 2 : i;
        }

        return params;
    }

    /**
     * Check variable is class
     * @param v
     * @returns {boolean}
     */
    static isClass(v: any) {
        return typeof v === 'function' && /^\s*class\s+/.test(v?.toString());
    }

    /**
     * Parse time codes
     * @param {string} text
     * @returns {Record<any, any>}
     */
    static timeCodes(text: string) {
        const timecodes = [];
        const map: Record<any, any> = {};

        let match = null;

        const regex = /(\d*):([0-5]?\d)/gm;
        const regexWithHours = /\s(\d*):([0-5]?\d):([0-5]?\d)\s/gm;

        while ((match = regex.exec(text)) != null) {
            timecodes.push(match[0]?.trim());
        }

        while ((match = regexWithHours.exec(text)) != null) {
            timecodes.push(match[0]?.trim());
        }

        for (const timecode of _.uniq(timecodes)) {
            const [ minute, second ] = timecode.split(':').map(i => +i);

            map[timecode] = minute * 60 + second;
        }

        return map;
    }

    /**
     * Parse time codes and replace
     * @param {string} content
     * @returns {string}
     */
    static parseTimeCodes(content: string) {
        const timecodes = Parse.timeCodes(content);

        for (const [ timecode, time ] of _.entries(timecodes)) {
            const onClick = ``
                + `const data = { detail: { time: ${time} } };`
                + `const customEvent = new CustomEvent('video:timecode-click', data);`
                + `document.dispatchEvent(customEvent);`;

            content = content.replace(
                timecode,
                `<timecode class="timecode" onClick="${onClick}" time="${time}" code="${timecode}">
                    ${timecode}
                </timecode>`,
            );
        }

        return content;
    }

    /**
     * Convert nl to br
     * @param {string} text
     * @returns {string}
     */
    static nlToBr(text: string) {
        return text.split('\n').join('<br/>');
    }

    /**
     * Remove gaps
     * @param {string} text
     * @returns {string}
     */
    static removeGaps(text: string) {
        return text?.replace(/([\r\n]){2,}/g, '\n\n');
    }

    /**
     * Parse and wrap emoji
     * @param {string} text
     * @returns {{className: string, text: string}}
     */
    static parseAndWrapEmoji(text: string) {
        const result = { text: '', className: '' };

        const defaultReplace = '<span class="fs-20 leading-[20px]">$&</span>';

        if (!text?.replace(regex.emoji, '')) {
            const match = text?.match(regex.emoji)?.length || 0;

            if (match <= 3) {
                result.className = 'flex h-auto huge-emoji';
                result.text = text.replace(regex.emoji, '<span class="fs-80 leading-[80px]">$&</span>');
            } else if (match <= 10) {
                result.className = 'flex h-auto medium-emoji';
                result.text = text.replace(regex.emoji, '<span class="fs-40 leading-[40px]">$&</span>');
            } else {
                result.text = text.replace(regex.emoji, defaultReplace);
            }

        } else {
            result.text = text.replace(regex.emoji, defaultReplace);
        }

        return result;
    }

    /**
     * Switch keyboard layout
     * @param {string} text
     * @returns {{ru: string, en: string}}
     */
    static keyboardLayout(text: string) {
        const result = {
            ruLayout: '',
            enLayout: '',
        };

        const ru = {
            'й': 'q', 'ц': 'w', 'у': 'e', 'к': 'r', 'е': 't', 'н': 'y', 'г': 'u',
            'ш': 'i', 'щ': 'o', 'з': 'p', 'х': '[', 'ъ': ']', 'ф': 'a', 'ы': 's',
            'в': 'd', 'а': 'f', 'п': 'g', 'р': 'h', 'о': 'j', 'л': 'k', 'д': 'l',
            'ж': ';', 'э': '\'', 'я': 'z', 'ч': 'x', 'с': 'c', 'м': 'v', 'и': 'b',
            'т': 'n', 'ь': 'm', 'б': ',', 'ю': '.',
        };

        const en = {
            'q': 'й', 'w': 'ц', 'e': 'у', 'r': 'к', 't': 'е', 'y': 'н', 'u': 'г',
            'i': 'ш', 'o': 'щ', 'p': 'з', '[': 'х', ']': 'ъ', 'a': 'ф', 's': 'ы',
            'd': 'в', 'f': 'а', 'g': 'п', 'h': 'р', 'j': 'о', 'k': 'л', 'l': 'д',
            ';': 'ж', '\'': 'э', 'z': 'я', 'x': 'ч', 'c': 'с', 'v': 'м', 'b': 'и',
            'n': 'т', 'm': 'ь', ',': 'б', '.': 'ю',
        };

        for (const letter of text.split('')) {
            result.ruLayout += ru[letter as keyof typeof ru] || letter;
            result.enLayout += en[letter as keyof typeof en] || letter;
        }

        return result;
    }

    /**
     * Parse and clean phone to E.164
     * @param {string} phone
     * @returns {string} E.164
     */
    static getPhone(phone: string) {
        return `+${phone.replace(/\D/g, '')}`;
    }

    /**
     * Parse phone
     * @param {string} phoneNumber
     * @returns {}
     */
    static phone(
        phoneNumber: string,
    ) {
        return phone(this.getPhone(phoneNumber));
    }

}


export { Parse };
