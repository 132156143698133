/**
 * IsEmailOrPhone validator
 *
 * @author: exode <hello@exode.ru>
 */

import { phone } from '@exode-team/phone';
import { isEmail, registerDecorator, ValidationOptions } from 'class-validator';

import { Parse } from '@/utils/Parse';


export function IsEmailOrPhone(
    validationOptions?: ValidationOptions,
    isOptional?: boolean,
) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: 'IsEmailOrPhone',
            target: object.constructor,
            propertyName: propertyName,
            constraints: [ isOptional ],
            options: validationOptions,
            validator: {
                validate(value: string) {
                    if (isOptional && !value) {
                        return true;
                    }

                    return value.includes('@')
                        ? isEmail(value)
                        : Parse.phone(value).isValid;
                },
                defaultMessage() {
                    return `${validationOptions?.message}`;
                },
            },
        });
    };
}
